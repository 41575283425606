import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import solitaire from "../../Assets/Projects/solitaire.png";
import roborally from "../../Assets/Projects/roborally.png";
import phonefreedom from "../../Assets/Projects/phonefreedom.png";
import engineersacademy from "../../Assets/Projects/frontend.png";
import matador from "../../Assets/Projects/matador111.png";
import database from "../../Assets/Projects/database.png";
import samat from "../../Assets/Projects/samat.png";
import nn from "../../Assets/Projects/nn.png";
import nykredit from "../../Assets/Projects/nykredit.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={solitaire}
              isBlog={false}
              title="Solitaire"
              description="Solitaire solver game using TensorFlow and Machine Learning. The camera detects the cards and is added to the columns and is run through the game logic to consider the next moves."
              ghLink="https://github.com/digitalbangash1/Gruppe10_CDIO_7kabalen"
              demoLink="https://www.youtube.com/watch?v=55JTx5wxQVc"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={roborally}
              isBlog={false}
              title="Roborally"
              description="The roborally digital version game was created in Java with GUI. The rules were implemented. Six players can play the game at a time. The player faces challenges on the board and the obstacles that stops player to finish the game. The player crossing all the checkpoints first wins the game."
              ghLink="https://github.com/digitalbangash1/Gruppe20_roborally_final"
            /*  demoLink="https://blogs.soumya-jit.tech/" */
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={phonefreedom}
              isBlog={false}
              title="Phone Freedom"
              description="An android app was developed in Java and Kotlin which functions as Auto Answer. The App sends automatic messages in case the person is not available. The messages are sent to the different social media apps like Whats App, Facebook Messenger, Telegram, Signal and Android default messaging service. The app allows user to be in Do Not Disturb mode and can be switched on and off."
              ghLink="https://github.com/digitalbangash1/PhoneFreedom"
              demoLink="https://youtu.be/k25zZx5HaYg"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={engineersacademy}
              isBlog={false}
              title="Engineers Academy"
              description="A frontend project was created using React and modern JavaScript Libraries. The project was about creating a platform for the students attending Software Technology course at DTU where they can gather all the information and knowledge about youtube videos, articles and websites than can help the students to learn about the course. "
              ghLink="https://github.com/digitalbangash1/Project-FrontEnd"
              demoLink="https://project-front-end-digitalbangash1.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={matador}
              isBlog={false}
              title="Monopoly Game"
              description="Monopoly Game was developed with GUI from DTU where players could compete each other and build property with the money. The richest player could win the game. There were other rules that could punish the player by sending them to jail or get other players to pay the players. Fun and exciting project."
              ghLink="https://github.com/digitalbangash1/20_Final"
            // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={database}
              isBlog={false}
              title="Database for Covid19 Vaccines"
              description="The database was created for Covid19 Vaccine Centers that could hold all the information about patients, workers and their certificates, locations, warehouses, bookings details of the patients
               and the types of vaccines."
              ghLink="https://github.com/digitalbangash1/Vaccine-1.0"
            // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col>
          
           <Col md={4} className="project-card">
            <ProjectCard
              imgPath={samat}
              isBlog={false}
              title="Webshop"
              description="Webshop created in React and dotnet Core with credit card paying technology using Swipe."
              ghLink="https://github.com/digitalbangash1/devops_frontend"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
            </Col>
             <Col md={4} className="project-card">
            <ProjectCard
              imgPath={nn}
              isBlog={false}
              title="Document Engine"
              description="Document Engine created for a project in Novo Nordisk"
              //ghLink="https://github.com/digitalbangash1/devops_frontend"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
            </Col>
            <Col md={4} className="project-card">
            <ProjectCard
              imgPath={nykredit}
              isBlog={false}
              title="Pricet tool Project"
              description="A pricetool project created for Nykredit."
              //ghLink="https://github.com/digitalbangash1/devops_frontend"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />            
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
