import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Software Engineer",
          "Currently working as a Cloud Engineer",
          "I Develop",
          "Web Applications",
          "Android Applications",
          "Database ",
          "Fullstack Applications" ,
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
