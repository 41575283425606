import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiLinux,
  SiVisualstudiocode,
  SiMicrosoftazure,
  SiMarkdown,
  SiGithub,
  SiVisualstudio,
  SiBootstrap,
  SiNpm,
  SiDotnet,
  SiIntellijidea,
  SiJirasoftware,
  SiGradle,
  SiMicrosoftoffice,
  SiDocker,
  SiVercel
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiLinux />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiIntellijidea />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMicrosoftazure />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGithub />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMarkdown />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudio />
        </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiBootstrap />        
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNpm />        
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiDotnet />        
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiJirasoftware />        
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGradle />        
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMicrosoftoffice />        
      </Col>
      
      <Col xs={4} md={2} className="tech-icons">
        <SiDocker />        
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVercel />        
      </Col>


      

    </Row>
  );
}

export default Toolstack;
